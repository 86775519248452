import BtnCompo from 'components/btn-compo';
import Footer from 'components/footer';
import React, { createContext, useContext, useEffect, useRef } from 'react';
import { Button, Col, Container, Form, Row } from 'react-bootstrap';

const SignerContext = createContext({
    firstName: "John",
    lastName: "Smith"
});

const boxStyle = {
    width: "100%",
    height: "300px",
    border: "1px solid #d3d3d3",
};

const WacomSignature = () => {
    const signatory = useContext(SignerContext);
    const chkB64 = useRef(true);
    const imageBox = useRef();
    const firstName = useRef("");
    const lastName = useRef("");
    const txtSignature = useRef();
    const txtDisplay = useRef();

    useEffect(() => {
        // When the form is first drawn call the bodyOnLoad() function to initialise the SigCaptX session
        window.bodyOnLoad(document.getElementById("txtDisplay"));
    }, []);

    const capture = (e) => {
        window.capture(firstName.current, lastName.current, imageBox.current, txtSignature.current, chkB64.current);
        console.log(window, signatory, txtSignature.current);
        if (txtSignature.current) localStorage.setItem("signature", JSON.stringify(txtSignature.current));
    };

    const clear = (e) => {
        console.log(window, signatory, txtSignature.current);
        if (txtSignature.current) window.clearSignature();
    };

    return (
        <Container className="h-100">
            <div className="main-content">
                <h1 className="page-title mb-5">SIGNATURE PAGE</h1>

                <div className="m-auto px-3 pb-5 d-flex flex-column  align-items-center  justify-content-center">
                    <div id="imageBox" ref={imageBox} style={boxStyle} className="border border-primary rounded p-1 sign-pad" title="Double-click a signature to display its details">

                    </div>
                    <ul className="btn-list mt-4 mb-2">
                        <li>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="fs-6"
                                title="Checks the signature hash" onClick={(e) => { capture(e) }}
                            >
                                Capture
                            </Button>
                        </li>
                        <li>
                            <Button
                                variant="outline-primary"
                                size="sm"
                                className="fs-6"
                                title="Clears the signature" onClick={(e) => { clear(e) }}
                            >
                                Clear
                            </Button>
                        </li>
                    </ul>

                    <textarea className='form-control w-100 mt-3' rows="10" id="txtDisplay" ref={txtDisplay}></textarea>

                    <Row>
                        <Form.Group as={Col} xs="6">
                            <Form.Label htmlFor="fname">First Name</Form.Label>
                            <Form.Control
                                id="fname"
                                type="text"
                                placeholder="First Name"
                                ref={firstName}
                                defaultValue={`${signatory.firstName}`}
                            />
                        </Form.Group>
                        <Form.Group as={Col} xs="6">
                            <Form.Label htmlFor="lname">Last Name</Form.Label>
                            <Form.Control
                                id="lname"
                                type="text"
                                placeholder="Last Name"
                                ref={lastName}
                                defaultValue={`${signatory.lastName}`}
                            />
                        </Form.Group>
                    </Row>
                </div>

                <Footer>
                    <ul className="btn-list">
                        <li className="back-li">
                            <BtnCompo
                                buttonAction={"/signature"}
                                buttonIcon={"/images/icon-back.png"}
                                buttonClass={"btn-back"}
                                buttonIconHeight={22}
                                buttonIconWidth={27}
                            />
                        </li>
                        <li>
                            <BtnCompo
                                buttonAction={"/rather-call-me"}
                                buttonText={"RATHER CALL ME"}
                                buttonOutline={true}
                                buttonClass={"btn-rather"}
                            />
                        </li>
                        <li>
                            {txtSignature.current && (
                                <BtnCompo buttonAction={"/document"} buttonText={"NEXT"} />
                            )}
                        </li>
                    </ul>
                </Footer>
            </div>
        </Container>
    );
};

export default WacomSignature;
