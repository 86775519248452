import ImgCompo from "components/img-compo";
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";

const RatherCallMe = () => {

  useEffect(() => {
    let profile; let address; let employeeDetail; let beneficiary; let addInfo; let checkInfoData; let totalCost; let agentCode; let bankingDetailsDO; let selfCoverData; let mainId; let extendedFamily; let signature;

    if (localStorage.getItem("profile")) profile = JSON.parse(localStorage.getItem("profile"));

    if (localStorage.getItem("profileAddress")) address = JSON.parse(localStorage.getItem("profileAddress"));

    if (localStorage.getItem("employeeDetails")) employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));

    if (localStorage.getItem("beneficiary")) beneficiary = JSON.parse(localStorage.getItem("beneficiary"));

    if (localStorage.getItem("addInfo")) addInfo = JSON.parse(localStorage.getItem("addInfo"));

    if (localStorage.getItem("checkInfoData")) checkInfoData = JSON.parse(localStorage.getItem("checkInfoData"));

    if (localStorage.getItem("totalCost")) totalCost = JSON.parse(localStorage.getItem("totalCost"));

    if (localStorage.getItem("agentCode")) agentCode = JSON.parse(localStorage.getItem("agentCode"));

    if (localStorage.getItem("bankingDetailsDO")) bankingDetailsDO = JSON.parse(localStorage.getItem("bankingDetailsDO"));

    if (localStorage.getItem("MainId")) mainId = JSON.parse(localStorage.getItem("MainId"));

    if (localStorage.getItem("selfCoverData")) selfCoverData = JSON.parse(localStorage.getItem("selfCoverData"));

    if (localStorage.getItem("extendedFamily")) extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));

    if (localStorage.getItem("signature")) signature = JSON.parse(localStorage.getItem("signature"));

    let payload = {
      sourceData: "funeralApp",
      RatherCall: "yes",
      ...profile,
      ...address,
      ...addInfo,
      ...checkInfoData,
      ...totalCost,
      ...bankingDetailsDO,
      ...mainId,
      ...selfCoverData,
      extendedFamily,
      ...beneficiary,
      ...employeeDetail,
      agentCode,
      signature,
    };
    var config = {
      method: "post",
      url: "https://apiv2.msgl.ink/",
      headers: {
        "content-type": "application/json",
      },
      data: {
        ...payload,
      },
    };
    axios(config)
      .then(function (response) {
        console.log(JSON.stringify(response.data));
      })
      .catch(function (error) {
        console.log(error);
      });

  }, [])

  return (
    <Container className="h-100">
      <div className="main-content text-center">
        <h1 className="page-title m-lg-b">SORRY TO SEE YOU GO</h1>
        <div className="content-block">
          <div className="content-text">
            <p>
              Thank you, one of our consultants will be in touch with you
              shortly.
            </p>
          </div>
          <div className="content-logo">
            <ImgCompo
              src="/images/logo.png"
              alt="Shield Life Funeral"
              className="mt-auto img-fluid"
              width={224}
              height={224}
            />
          </div>
        </div>
      </div>
    </Container>
  );
};

export default RatherCallMe;
