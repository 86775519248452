import BtnCompo from "components/btn-compo";
import ImgCompo from "components/img-compo";
import Footer from "components/footer";

import React, { useEffect, useState } from "react";
import { Container, Form, Button, InputGroup } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const Home = () => {
  const [isAgent, setIsAgent] = useState(false);
  const [agentCode, setAgentCode] = useState("");
  const [validated, setValidated] = useState(false);
  const navigate = useNavigate();


  useEffect(() => {
    localStorage.clear();
  }, [])

  const handleSubmit = (event) => {
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }
    if (agentCode) { localStorage.setItem("agentCode", JSON.stringify(agentCode)); navigate("/terms-condition"); }
    else setValidated(true);
  }


  return (
    <Container>
      <div className="d-flex flex-column m-auto align-items-center">
        <ImgCompo
          src="/images/logo.png"
          alt="Shield Life Funeral"
          className="m-lg-b img-fluid"
          width={224}
          height={224}
        />

        {!isAgent &&
          <div>
            <div className="mb-2">
              <BtnCompo
                buttonStyle={"small"}
                buttonText={"AGENT"}
                onClick={() => setIsAgent(true)}
              />
            </div>
            <BtnCompo
              buttonStyle={"small"}
              buttonText={"PERSONAL"}
              buttonAction={"/terms-condition"}
              onClick={() => { setIsAgent(false); setAgentCode(""); localStorage.removeItem("agentCode") }}
            />
          </div>
        }
      </div>

      {isAgent &&
        <div>
          <Form
            noValidate
            validated={validated}
            onSubmit={handleSubmit}
          >
            <Form.Group className='mb-2' controlId='formForceNumber'>
              <Form.Label className='Label'>Agent Code</Form.Label>
              <InputGroup hasValidation>
                <Form.Control className='Control' type='text' required value={agentCode} onChange={(e) => setAgentCode(e.target.value)} />
                <Form.Control.Feedback type="invalid">
                  Please Enter Agent Code.
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>

            <Footer>
              <ul className="btn-list">
                <li className="back-li">
                  <BtnCompo
                    onClick={() => setIsAgent(false)}
                    buttonIcon={"/images/icon-back.png"}
                    buttonClass={"btn-back"}
                    buttonIconHeight={22}
                    buttonIconWidth={27}
                  />
                </li>
                <li>
                  <BtnCompo
                    buttonAction={"/decline"}
                    buttonText={"DECLINE"}
                    buttonOutline={true}
                  />
                </li>
                <li>
                  <Button variant="primary" type="submit">
                    NEXT
                  </Button>
                </li>
              </ul>
            </Footer>
          </Form>
        </div>}
    </Container>
  );
};

export default Home;
