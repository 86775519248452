import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import ImgCompo from "components/img-compo";
import React, { useEffect, useState } from "react";
import { Button, Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
// import axios from "axios";

const QuoteSummary = () => {

  const [selfCoverData, setSelfCoverData] = useState({});
  const [profileData, setProfileData] = useState();
  const [spouceCover, setSpouceCover] = useState([]);
  const [memberCover, setMemberCover] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [finalTotal, setFinalTotal] = useState(0);
  // const [premiumWaiverData, setPremiumWaiverData] = useState([]);
  const [premiumWaiverAmount, setPremiumWaiverAmount] = useState(0);
  const [premiumWaiver, setPremiumWaiver] = useState(false);
  const [premiumHolidayAmount, setPremiumHolidayAmount] = useState(0);
  const [premiumHoliday, setPremiumHoliday] = useState(false);
  const [totalIllness, setTotalIllness] = useState(0);
  const [show, setShow] = useState(false);
  const [type, setType] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    // axios
    //   .get("./waivedPremium.json")
    //   .then((res) => {
    //     if (res.data) setPremiumWaiverData(res.data);
    //   })
    //   .catch((err) => console.log(err));

    if (localStorage.getItem('selfCoverData')) {
      let data = JSON.parse(localStorage.getItem("selfCoverData"));
      setSelfCoverData(data);
    } else navigate("/select-cover");

    if (localStorage.getItem('profile')) {
      let data = JSON.parse(localStorage.getItem("profile"));
      setProfileData(data);
    }

    if (localStorage.getItem('extendedFamily')) {
      let data = JSON.parse(localStorage.getItem('extendedFamily'));
      let spouceData = data.filter(item => item.memberRelation === 'spouse');
      let memberData = data.filter(item => item.memberRelation !== 'spouse');
      setSpouceCover(spouceData);
      setMemberCover(memberData);
    }

    if (localStorage.getItem('totalCost')) {
      let data = JSON.parse(localStorage.getItem("totalCost"));
      setTotalCost(data.totalCoverCost);
      setFinalTotal(data.finalTotal);
      setPremiumWaiverAmount(data.premiumWaiverAmount);
      setPremiumWaiver(data.premiumWaiver);
      setPremiumHoliday(data.premiumHoliday);
      setPremiumHolidayAmount(data.premiumHolidayAmount);
      setTotalIllness(data.totalIllness)
    }
  }, [navigate])

  useEffect(() => {
    // let waiverBenefit = 0;
    // if (premiumWaiverData.length > 0) {
    //   premiumWaiverData.forEach((item) => {
    //     if (profileData.mainAge <= item.agemax && profileData.mainAge >= item.agemin) waiverBenefit = item.cost;
    //   });
    // }

    if (selfCoverData && !localStorage.getItem('totalCost') && memberCover && spouceCover) {
      const sumArr = memberCover.reduce((a, b) => a + b.premium, 0);
      const spouceFuneralArr = spouceCover.reduce((a, b) => a + b.premium, 0);
      const spouceAccidentalArr = spouceCover.reduce((a, b) => a + b.spouseAccidentalCost, 0);
      const spouceIncomeArr = spouceCover.reduce((a, b) => a + b.spouseIncomeCost, 0);
      const spouceIllnessArr = spouceCover.reduce((a, b) => a + b.spouseIllnessBenefits, 0);
      const totalIll = Number(selfCoverData.illnessBenefits) + Number(spouceIllnessArr);
      const total = sumArr + Number(selfCoverData.totalCost) + Number(spouceFuneralArr) + Number(spouceAccidentalArr) + Number(spouceIncomeArr);
      const feeTotal = Number(total) + Number(10);

      setPremiumWaiverAmount((feeTotal * 7.5 / 100).toFixed(2));
      setPremiumHolidayAmount((total * 0.03 / 100).toFixed(2));
      setTotalIllness(totalIll);
      setTotalCost(parseFloat(total).toFixed(2));
      setFinalTotal(parseFloat(feeTotal).toFixed(2));
    }
  }, [memberCover, profileData, selfCoverData, spouceCover]);

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  const checkOption = (e, value, type) => {
    if (e === true) {
      setType(type);
      handleShow();

      if (type === "holiday") {
        let fee = Number(totalCost) + Number(10) + Number(value);
        let count = (fee * 7.5) / 100;
        setPremiumWaiverAmount((count).toFixed(2));
      }
      let total = Number(finalTotal) + Number(value);
      setFinalTotal(parseFloat(total).toFixed(2));
    } else {
      if (type === "holiday") {
        let fee = Number(totalCost) + Number(10);
        let count = (fee * 7.5) / 100;
        setPremiumWaiverAmount((count).toFixed(2));
      }
      let total = Number(finalTotal) - Number(value);
      setFinalTotal(parseFloat(total).toFixed(2));
    }
  }

  const btnClick = () => {
    let totalCosts = {
      "totalIllness": totalIllness,
      "totalCoverCost": totalCost,
      "finalTotal": finalTotal,
      "premiumWaiver": premiumWaiver,
      "premiumWaiverAmount": premiumWaiverAmount,
      "premiumHoliday": premiumHoliday,
      "premiumHolidayAmount": premiumHolidayAmount
    }

    localStorage.setItem('totalCost', JSON.stringify(totalCosts));
    localStorage.setItem('selfCoverData', JSON.stringify(selfCoverData));

    const finalArr = spouceCover.concat(memberCover)
    localStorage.setItem('extendedFamily', JSON.stringify(finalArr));
    localStorage.setItem('indexPage', finalArr.length);
    navigate("/employee-details");
  }

  const deleteFamilyCover = (id) => {
    let newExtendedFamilyArray = memberCover.filter((data) => data.id !== id);
    localStorage.removeItem('totalCost');
    setMemberCover(newExtendedFamilyArray);
  }

  const deleteSelfCover = (type) => {
    if (type === 'accidental') {
      setSelfCoverData(data => { data.totalCost = data.totalCost - data.accidentalCost; data.accidentalCost = 0; data.accidentalAmount = 0; });
    } if (type === 'income') {
      setSelfCoverData(data => { data.totalCost = data.totalCost - data.incomeCost; data.incomeCost = 0; data.incomeAmount = 0; });
    }

    localStorage.removeItem('totalCost');
    localStorage.removeItem('selfCoverData');
    localStorage.setItem('selfCoverData', JSON.stringify(selfCoverData));
    let coverData = JSON.parse(localStorage.getItem("selfCoverData"));
    setSelfCoverData(coverData);
  }

  const deleteSpouceCover = (type, id) => {
    const newArray = spouceCover.map((item) => {
      if (id === item.id && type === 'accidental') return { ...item, spouseAccidentalAmount: 0, spouseAccidentalCost: 0 };
      else if (id === item.id && type === 'income') return { ...item, spouseIncomeAmount: 0, spouseIncomeCost: 0 };
      else return item;
    });

    localStorage.removeItem('totalCost');
    setSpouceCover(newArray);
  }

  return (
    <Container className="h-100">
      <div className="main-content">
        <h1 className="page-title mb-5">QUOTE SUMMARY</h1>
        <div>
          <div className="page-header">YOUR PLAN</div>
          <div className="mb-2">
            <Table hover responsive borderless>
              <thead>
                <tr>
                  <th width="126">MAIN MEMBER</th>
                  <th width="76">COVER TYPE</th>
                  <th width="88">COVER AMOUNT</th>
                  <th width="52">PREMIUM</th>
                  <th width="40"></th>
                </tr>
              </thead>
              <tbody>
                {selfCoverData?.funeralCost > 0 && <tr>
                  <td>{profileData?.fullNames} {profileData?.surname}</td>
                  <td>FUNERAL</td>
                  <td>R{selfCoverData.funeralAmount}</td>
                  <td>R{selfCoverData.funeralCost.toFixed(2)}</td>
                  <td>
                    <ul className="btn-list">
                      <li>
                        <Button
                          variant="link"
                          size="sm"
                          href="/my-cover"
                          className="p-0"
                        >
                          <ImgCompo
                            src={"/images/icon-edit.png"}
                            alt="edit"
                            height={10}
                            width={10}
                          />
                        </Button>
                      </li>
                    </ul>
                  </td>
                </tr>
                }

                {selfCoverData?.accidentalCost > 0 && <tr>
                  <td>{profileData?.fullNames} {profileData?.surname}</td>
                  <td>ACCIDENTAL</td>
                  <td>R{selfCoverData.accidentalAmount}</td>
                  <td>R{selfCoverData.accidentalCost.toFixed(2)}</td>
                  <td>
                    <ul className="btn-list">
                      <li>
                        <Button
                          variant="link"
                          size="sm"
                          href="/my-cover"
                          className="p-0"
                        >
                          <ImgCompo
                            src={"/images/icon-edit.png"}
                            alt="edit"
                            height={10}
                            width={10}
                          />
                        </Button>
                      </li>
                      <li>
                        <Button
                          variant="link"
                          size="sm"
                          className="p-0"
                          onClick={() => { deleteSelfCover("accidental") }}
                        >
                          <ImgCompo
                            src={"/images/icon-remove.png"}
                            alt="remove"
                            height={10}
                            width={10}
                          />
                        </Button>
                      </li>
                    </ul>
                  </td>
                </tr>}

                {selfCoverData?.incomeCost > 0 && <tr>
                  <td>{profileData?.fullNames} {profileData?.surname}</td>
                  <td>INCOME</td>
                  <td>R{selfCoverData.incomeAmount}</td>
                  <td>R{selfCoverData.incomeCost.toFixed(2)}</td>
                  <td>
                    <ul className="btn-list">
                      <li>
                        <Button
                          variant="link"
                          size="sm"
                          href="/my-cover"
                          className="p-0"
                        >
                          <ImgCompo
                            src={"/images/icon-edit.png"}
                            alt="edit"
                            height={10}
                            width={10}
                          />
                        </Button>
                      </li>
                      <li>
                        <Button
                          variant="link"
                          size="sm"
                          className="p-0"
                          onClick={() => { deleteSelfCover("income") }}
                        >
                          <ImgCompo
                            src={"/images/icon-remove.png"}
                            alt="remove"
                            height={10}
                            width={10}
                          />
                        </Button>
                      </li>
                    </ul>
                  </td>
                </tr>
                }
              </tbody>
            </Table>
          </div>

          <div>
            {spouceCover.length > 0 &&
              <Table hover responsive borderless>
                <thead>
                  <tr>
                    <th width="126">SPOUSE MEMBER</th>
                    <th width="76">COVER TYPE</th>
                    <th width="88">COVER AMOUNT</th>
                    <th width="52">PREMIUM</th>
                    <th width="40"></th>
                  </tr>
                </thead>
                <tbody>
                  {spouceCover.map((item) => {
                    return <>
                      {<tr key={item.premium}>
                        <td>{item.name} {item.surname}</td>
                        <td>FUNERAL</td>
                        <td>R{item.benefit}</td>
                        <td>R{item.premium.toFixed(2)}</td>
                        <td>
                          <ul className="btn-list">
                            <li>
                              <Button
                                variant="link"
                                size="sm"
                                href={`/extended-family-cover?cover=` + item.memberRelation + `&index=` + item.id}
                                className="p-0"
                              >
                                <ImgCompo
                                  src={"/images/icon-edit.png"}
                                  alt="edit"
                                  height={10}
                                  width={10}
                                />
                              </Button>
                            </li>

                          </ul>
                        </td>
                      </tr>}
                      {item.spouseAccidentalAmount > 0 &&
                        <tr key={item.spouseAccidentalCost}>
                          <td>{item.name} {item.surname}</td>
                          <td>Accidental</td>
                          <td>R{item.spouseAccidentalAmount}</td>
                          <td>R{item.spouseAccidentalCost.toFixed(2)}</td>
                          <td>
                            <ul className="btn-list">
                              <li>
                                <Button
                                  variant="link"
                                  size="sm"
                                  href={`/extended-family-cover?cover=` + item.memberRelation + `&index=` + item.id}
                                  className="p-0"
                                >
                                  <ImgCompo
                                    src={"/images/icon-edit.png"}
                                    alt="edit"
                                    height={10}
                                    width={10}
                                  />
                                </Button>
                              </li>
                              <li>
                                <Button
                                  variant="link"
                                  size="sm"
                                  className="p-0"
                                  onClick={() => { deleteSpouceCover("accidental", item.id) }}
                                >
                                  <ImgCompo
                                    src={"/images/icon-remove.png"}
                                    alt="remove"
                                    height={10}
                                    width={10}
                                  />
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>}
                      {item.spouseIncomeAmount > 0 &&
                        <tr key={item.spouseIncomeCost}>
                          <td>{item.name} {item.surname}</td>
                          <td>Income</td>
                          <td>R{item.spouseIncomeAmount}</td>
                          <td>R{item.spouseIncomeCost.toFixed(2)}</td>
                          <td>
                            <ul className="btn-list">
                              <li>
                                <Button
                                  variant="link"
                                  size="sm"
                                  href={`/extended-family-cover?cover=` + item.memberRelation + `&index=` + item.id}
                                  className="p-0"
                                >
                                  <ImgCompo
                                    src={"/images/icon-edit.png"}
                                    alt="edit"
                                    height={10}
                                    width={10}
                                  />
                                </Button>
                              </li>
                              <li>
                                <Button
                                  variant="link"
                                  size="sm"
                                  className="p-0"
                                  onClick={() => { deleteSpouceCover("income", item.id) }}
                                >
                                  <ImgCompo
                                    src={"/images/icon-remove.png"}
                                    alt="remove"
                                    height={10}
                                    width={10}
                                  />
                                </Button>
                              </li>
                            </ul>
                          </td>
                        </tr>}
                    </>
                  })}
                </tbody>
              </Table>
            }
          </div>

          <div>
            {memberCover.length > 0 &&
              <Table hover responsive borderless>
                <thead>
                  <tr>
                    <th width="126">COVERED MEMBER</th>
                    <th width="76">COVER TYPE</th>
                    <th width="88">COVER AMOUNT</th>
                    <th width="52">PREMIUM</th>
                    <th width="40"></th>
                  </tr>
                </thead>
                <tbody>
                  {memberCover.map((item) => {
                    return <tr key={item.id}>
                      <td>{item.name} {item.surname}</td>
                      <td>FUNERAL</td>
                      <td>R{item.benefit}</td>
                      <td>R{item.premium.toFixed(2)}</td>
                      <td>
                        <ul className="btn-list">
                          <li>
                            <Button
                              variant="link"
                              size="sm"
                              href={`/extended-family-cover?cover=` + item.memberRelation + `&index=` + item.id}
                              className="p-0"
                            >
                              <ImgCompo
                                src={"/images/icon-edit.png"}
                                alt="edit"
                                height={10}
                                width={10}
                              />
                            </Button>
                          </li>
                          <li>
                            <Button
                              variant="link"
                              size="sm"
                              className="p-0"
                              onClick={() => { deleteFamilyCover(item.id) }}
                            >
                              <ImgCompo
                                src={"/images/icon-remove.png"}
                                alt="remove"
                                height={10}
                                width={10}
                              />
                            </Button>
                          </li>
                        </ul>
                      </td>
                    </tr>
                  })}
                </tbody>
              </Table>
            }
          </div>

          <div>
            <Table hover responsive borderless>
              <thead>
                <tr>
                  <th width="126">MAIN/SPOUSE MEMBER</th>
                  <th width="76">COVER TYPE</th>
                  <th width="88">COVER AMOUNT</th>
                  <th width="52">PREMIUM</th>
                  <th width="40"></th>
                </tr>
              </thead>
              <tbody>
                {selfCoverData?.funeralCost > 0 &&
                  <tr>
                    <td>{profileData?.fullNames} {profileData?.surname}</td>
                    <td>ILLNESS BENEFITS</td>
                    <td>R{selfCoverData.illnessBenefits}</td>
                    <td>R0</td>
                  </tr>
                }
                {spouceCover.length > 0 && spouceCover.map((item) => {
                  return (<tr key={item.id}>
                    <td>{item.name} {item.surname}</td>
                    <td>ILLNESS BENEFITS</td>
                    <td>R{item.spouseIllnessBenefits}</td>
                    <td>R0</td>
                  </tr>
                  )
                })
                }
              </tbody>
            </Table>
          </div>
          <hr className="mt-0" />
          <p className="text-start text-sm mb-2">* INCLUDED TOTAL ILLNESS BENEFITS OF FUNERAL COVER <strong>R{totalIllness}</strong></p>
        </div>
        <Row className="align-items-end g-4 mb-5">
          <Col xs="auto">
            <BtnCompo
              buttonAction={"/extended-family-selection"}
              buttonText={"ADD A MEMBER"}
              buttonIcon={"/images/icon-add-member.png"}
              buttonOutline={true}
              buttonClass={"fw-normal px-2"}
            />
          </Col>
          <Col xs="auto">
            <Table borderless className="price-table">
              <tbody>
                <tr>
                  <td>TOTAL</td>
                  {totalCost ? <td>R{totalCost}</td> : ''}
                </tr>
                <tr>
                  <td>POLICY FEE</td>
                  <td>R10.00</td>
                </tr>
              </tbody>
            </Table>
          </Col>
        </Row>

        <div className="mx-auto mb-3">
          <h2 className="title-sm text-primary text-center">
            I WOULD LIKE TO INCLUDE
          </h2>
          <div>
            <Form.Check
              type="checkbox"
              id="check2"
              value={premiumHolidayAmount}
              checked={premiumHoliday}
              label={"PREMIUM HOLIDAY @ R" + premiumHolidayAmount}
              onChange={(e) => { checkOption(e.target.checked, premiumHolidayAmount, "holiday"); setPremiumHoliday(e.target.checked) }}
            />
          </div>

          <Form.Check
            type="checkbox"
            id="check1"
            label={"PREMIUM WAIVER @ R" + premiumWaiverAmount}
            value={premiumWaiverAmount}
            checked={premiumWaiver}
            onChange={(e) => { checkOption(e.target.checked, premiumWaiverAmount, "waiver"); setPremiumWaiver(e.target.checked) }}
          />


        </div>
        <div>
          <h2 className="title-sm text-primary text-center">
            GRAND TOTAL MONTHLY PREMIUM
          </h2>

          <h3 className="title-sm text-primary text-center">{finalTotal ? `R${finalTotal}` : `R${totalCost}`}</h3>
        </div>
        <Footer>
          <ul className="btn-list">
            <li className="back-li">
              <BtnCompo
                buttonAction={"/extended-family-selection"}
                buttonIcon={"/images/icon-back.png"}
                buttonClass={"btn-back"}
                buttonIconHeight={22}
                buttonIconWidth={27}
              />
            </li>
            <li>
              <BtnCompo
                buttonAction={"/rather-call-me"}
                buttonText={"RATHER CALL ME"}
                buttonOutline={true}
                buttonClass={"btn-rather"}
              />
            </li>
            <li>
              <Button
                variant="primary"
                onClick={(e) => { btnClick(e) }}
                className="btn-rather"
              >LET’S DO IT!</Button>
            </li>
          </ul>
        </Footer>
      </div>

      <Modal show={show} onHide={handleClose} centered backdrop={false}>
        <Modal.Header closeButton>
          <Modal.Title>NOTE:</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div>
            {type === 'waiver' && <p>On the death of the Principal Member and/or Premium Payer, the premium is waived for 6 months. This benefit may be selected as an addition benefit.</p>}
            {type === 'holiday' && <p>If the Principal member is retrenched or becomes temporary disabled (prior to retirement), the premiums will be waived for up to 3 months. This benefit has a limitation of one (1) claim. This benefit may be selected as an additional benefit.</p>}
          </div>
        </Modal.Body>
      </Modal>
    </Container >
  );
};

export default QuoteSummary;
