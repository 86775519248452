import BtnCompo from "components/btn-compo";
import Footer from "components/footer";
import React, { useEffect, useState } from "react";
import { Button, Container, Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const AddInfo = () => {

  const [affordableFunerral, setAffordableFunerral] = useState('');
  const [funeralMyself, setFuneralMyself] = useState('');
  const [funeralMyFamily, setFuneralMyFamily] = useState('');
  const [funeralExtendedFamily, setFuneralExtendedFamily] = useState('');
  const [additionalBenifits, setAdditionalBenifits] = useState('');
  const [sendInfo, setSendInfo] = useState('yes');
  const [other, setOther] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem('addInfo')) {
      let reasons = JSON.parse(localStorage.getItem('addInfo'))
      if (reasons.affordableFunerral) setAffordableFunerral(reasons.affordableFunerral);
      if (reasons.funeralMyself) setFuneralMyself(reasons.funeralMyself);
      if (reasons.funeralMyFamily) setFuneralMyFamily(reasons.funeralMyFamily);
      if (reasons.funeralExtendedFamily) setFuneralExtendedFamily(reasons.funeralExtendedFamily);
      if (reasons.additionalBenifits) setAdditionalBenifits(reasons.additionalBenifits);
      if (reasons.other) setOther(reasons.other);
      if (reasons.sendInfo) setSendInfo(reasons.sendInfo);
    }
  }, [])

  const handleSubmit = () => {
    let reasons = {};
    if (affordableFunerral) reasons.affordableFunerral = affordableFunerral;
    if (funeralMyself) reasons.funeralMyself = funeralMyself;
    if (funeralMyFamily) reasons.funeralMyFamily = funeralMyFamily;
    if (funeralExtendedFamily) reasons.funeralExtendedFamily = funeralExtendedFamily;
    if (additionalBenifits) reasons.additionalBenifits = additionalBenifits;
    if (other) reasons.other = other;
    if (sendInfo) reasons.sendInfo = sendInfo

    localStorage.setItem('addInfo', JSON.stringify(reasons));
    navigate("/signature-confirm");
  };

  return (
    <Container className="h-100">
      <div className="main-content">
        <h1 className="page-title mb-5">REASONS FOR CHOOSING THE PLAN</h1>

        <Form noValidate onSubmit={handleSubmit} >
          <div className="mx-auto px-3 pb-5">
            <Form.Check
              checked={affordableFunerral}
              type="checkbox"
              id="check1"
              label="AFFORDABLE FUNERAL COVER"
              value={"AFFORDABLE FUNERAL COVER"}
              onChange={(e) => { if (e.target.checked) setAffordableFunerral(e.target.value); else setAffordableFunerral(''); }}
            />

            <Form.Check
              checked={funeralMyself}
              type="checkbox"
              id="check2"
              label="FUNERAL COVER FOR MYSELF"
              value={"FUNERAL COVER FOR MYSELF"}
              onChange={(e) => { if (e.target.checked) setFuneralMyself(e.target.value); else setFuneralMyself(''); }}
            />
            <Form.Check
              checked={funeralMyFamily ? true : false}
              type="checkbox"
              id="check3"
              label="FUNERAL COVER FOR MY FAMILY"
              value={"FUNERAL COVER FOR MY FAMILY"}
              onChange={(e) => { if (e.target.checked) setFuneralMyFamily(e.target.value); else setFuneralMyFamily(''); }}
            />
            <Form.Check
              checked={funeralExtendedFamily ? true : false}
              type="checkbox"
              id="check4"
              label="FUNERAL COVER FOR MY EXTENDED FAMILY"
              value={"FUNERAL COVER FOR MY EXTENDED FAMILY"}
              onChange={(e) => { if (e.target.checked) setFuneralExtendedFamily(e.target.value); else setFuneralExtendedFamily(''); }}
            />
            <Form.Check
              checked={additionalBenifits ? true : false}
              type="checkbox"
              id="check5"
              label="ADDITIONAL BENIFITS"
              value={"ADDITIONAL BENIFITS"}
              onChange={(e) => { if (e.target.checked) setAdditionalBenifits(e.target.value); else setAdditionalBenifits(''); }}
            />
            <Form.Check
              checked={other ? true : false}
              type="checkbox"
              id="check6"
              label="I AM CONFIDENT THAT THE FAIR TREATMENT OF POLICY HOLDERS IS CENTRAL TO THE INSURER’S CULTURE"
              value={"I AM CONFIDENT THAT THE FAIR TREATMENT OF POLICY HOLDERS IS CENTRAL TO THE INSURER’S CULTURE"}
              onChange={(e) => { if (e.target.checked) setOther(e.target.value); else setOther(''); }}
            />

          </div>
          <Form.Group>
            <Form.Label htmlFor="rankTitle">May we in future send you information regarding Shield Life’s products and benefits</Form.Label>
            <Form.Select
              required
              aria-describedby="inputGroupPrepend"
              aria-label="RankTitle"
              value={sendInfo}
              onChange={(e) => setSendInfo(e.target.value)}
            >
              <option value="yes">Yes</option>
              <option value="no">No</option>

            </Form.Select>
          </Form.Group>

          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/check-info"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                />
              </li>
              <li>
                <Button variant="primary" type="submit">
                  NEXT
                </Button>
              </li>
            </ul>
          </Footer>
        </Form>
      </div>
    </Container>
  );
};

export default AddInfo;
