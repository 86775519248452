import BtnCompo from "components/btn-compo";
import React, { useEffect, useState } from "react";
import Footer from "components/footer";
import { Button, Form, Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const SignConfirm = () => {
  const [validated, setValidated] = useState(false);
  const [confirmsignYes, setConfirmsignYes] = useState("");
  const [profile, setProfile] = useState();
  const [address, setAddress] = useState();
  const [addInfo, setAddInfo] = useState();
  const [checkInfoData, setCheckInfoData] = useState();
  const [totalCost, setTotalCost] = useState();
  const [bankingDetailsDO, setBankingDetailsDO] = useState();
  const [mainId, setMainId] = useState();
  const [selfCoverData, setSelfCoverData] = useState();
  const [extendedFamily, setExtendedFamily] = useState([]);
  const [beneficiary, setBeneficiary] = useState();
  const [employeeDetails, setEmployeeDetails] = useState();
  const [agentCode, setAgentCode] = useState();

  const navigate = useNavigate();

  useEffect(() => {
    if (localStorage.getItem("agentCode")) {
      let agentCode = JSON.parse(localStorage.getItem("agentCode"));
      setAgentCode(agentCode);
    }

    if (localStorage.getItem("profile")) {
      let profile = JSON.parse(localStorage.getItem("profile"));
      setProfile(profile);
    }

    if (localStorage.getItem("profileAddress")) {
      let address = JSON.parse(localStorage.getItem("profileAddress"));
      setAddress(address);
    }

    if (localStorage.getItem("addInfo")) {
      let addInfo = JSON.parse(localStorage.getItem("addInfo"));
      setAddInfo(addInfo);
    }

    if (localStorage.getItem("checkInfoData")) {
      let checkInfoData = JSON.parse(localStorage.getItem("checkInfoData"));
      setCheckInfoData(checkInfoData);
    }

    if (localStorage.getItem("totalCost")) {
      let totalCost = JSON.parse(localStorage.getItem("totalCost"));
      setTotalCost(totalCost);
    }

    if (localStorage.getItem("bankingDetailsDO")) {
      let bankingDetailsDO = JSON.parse(
        localStorage.getItem("bankingDetailsDO")
      );
      setBankingDetailsDO(bankingDetailsDO);
    }

    if (localStorage.getItem("MainId")) {
      let mainId = JSON.parse(localStorage.getItem("MainId"));
      setMainId(mainId);
    }

    if (localStorage.getItem("selfCoverData")) {
      let selfCoverData = JSON.parse(localStorage.getItem("selfCoverData"));
      setSelfCoverData(selfCoverData);
    }

    if (localStorage.getItem("extendedFamily")) {
      let extendedFamily = JSON.parse(localStorage.getItem("extendedFamily"));
      setExtendedFamily(extendedFamily);
    }

    if (localStorage.getItem("beneficiary")) {
      const beneficiary = JSON.parse(localStorage.getItem("beneficiary"));
      setBeneficiary(beneficiary);
    }

    if (localStorage.getItem("employeeDetails")) {
      let employeeDetail = JSON.parse(localStorage.getItem("employeeDetails"));
      setEmployeeDetails(employeeDetail);
    }
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    const form = event.currentTarget;

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }

    // let extendedFamilyArray = [];
    // let extendedFamilyObj = {};

    // if (extendedFamily.length > 0) {
    //   extendedFamily.forEach((item, index) => {
    //     if (item.memberRelation !== 'spouse') {
    //       extendedFamilyArray.push({
    //         ['id' + index]: index,
    //         ['memberRelation' + index]: item.memberRelation,
    //         ['relationship' + index]: item.relationship,
    //         ['name' + index]: item.name,
    //         ['surname' + index]: item.surname,
    //         ['dateOfBirth' + index]: item.dateOfBirth,
    //         ['age' + index]: item.age,
    //         ['gender' + index]: item.gender,
    //         ['benefit' + index]: item.benefit,
    //         ['premium' + index]: item.premium,
    //       });
    //     } else {
    //       extendedFamilyArray.push({
    //         ['id' + index]: index,
    //         ['memberRelation' + index]: item.memberRelation,
    //         ['relationship' + index]: item.relationship,
    //         ['name' + index]: item.name,
    //         ['surname' + index]: item.surname,
    //         ['dateOfBirth' + index]: item.dateOfBirth,
    //         ['age' + index]: item.age,
    //         ['gender' + index]: item.gender,
    //         ['benefit' + index]: item.benefit,
    //         ['premium' + index]: item.premium,
    //         ['spouseAccidentalAmount' + index]: item.spouseAccidentalAmount,
    //         ['spouseAccidentalCost' + index]: item.spouseAccidentalCost,
    //         ['spouseIncomeAmount' + index]: item.spouseIncomeAmount,
    //         ['spouseIncomeCost' + index]: item.spouseIncomeCost,
    //         ['spouseIllnessBenefits' + index]: item.spouseIllnessBenefits,
    //         ['spouseTotalCost' + index]: item.spouseTotalCost,
    //       });
    //     }
    //   });
    //   for (let i = 0; i < extendedFamilyArray.length; i++) {
    //     Object.assign(extendedFamilyObj, extendedFamilyArray[i]);
    //   }
    // }

    if (confirmsignYes === "Yes") navigate("/signature");
    else if (confirmsignYes === "No") {
      let payload = {
        sourceData: "funeralApp",
        confirmsignYes: confirmsignYes,
        addInfo,
        extendedFamily,
        agentCode,
        bankingDetailsDO,
        ...profile,
        ...address,
        ...checkInfoData,
        ...totalCost,
        ...mainId,
        ...selfCoverData,
        ...beneficiary,
        ...employeeDetails,
      };

      var config = {
        method: "post",
        url: "https://apiv2.msgl.ink/",
        headers: {
          "content-type": "application/json",
        },
        data: {
          ...payload,
        },
      };
      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
        })
        .catch(function (error) {
          console.log(error);
        });
      navigate("/thankyou");
    }

    setValidated(true);
  };

  return (
    <Container>
      <div className="main-content">
        <h1 className="page-title mb-5">Signature Option</h1>

        <Form noValidate onSubmit={handleSubmit} validated={validated}>
          <Form.Group className="mb-2" controlId="sourceIncome">
            <Form.Label className="Label mb-2">
              * Please select one the following signing options
            </Form.Label>
            <Form.Check
              required
              type="radio"
              id="sign"
              label="Sign now"
              name="group1"
              value={confirmsignYes}
              onChange={(e) => setConfirmsignYes("Yes")}
            />
            <Form.Check
              type="radio"
              label={
                "Send a signing link to the following mobile number - " +
                profile?.cellNumber
              }
              id="link"
              name="group1"
              value={confirmsignYes}
              onChange={(e) => setConfirmsignYes("No")}
            />
            <Form.Control.Feedback type="invalid">
              Please choose a Source Income.
            </Form.Control.Feedback>
          </Form.Group>
          <Footer>
            <ul className="btn-list">
              <li className="back-li">
                <BtnCompo
                  buttonAction={"/add-info"}
                  buttonIcon={"/images/icon-back.png"}
                  buttonClass={"btn-back"}
                  buttonIconHeight={22}
                  buttonIconWidth={27}
                />
              </li>
              <li>
                <BtnCompo
                  buttonAction={"/rather-call-me"}
                  buttonText={"RATHER CALL ME"}
                  buttonOutline={true}
                  buttonClass={"btn-rather"}
                />
              </li>
              <li>
                <Button variant="primary" type="submit">
                  NEXT
                </Button>
              </li>
            </ul>
          </Footer>
        </Form>
      </div>
    </Container>
  );
};

export default SignConfirm;
